/* src/pages/SinglePageConnectedArcSpreadsheet.css */

/* The scene container sets up 3D perspective */
.scene {
  position: relative;
  width: 900px;
  height: 700px;
  margin: 40px auto;
  perspective: 1200px;
  background: #fafafa;
}

/* Rotation slider styling */
.rotation-slider {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
  display: flex;
  align-items: center;
  gap: 8px;
}

/* The structure container holds all pages and rotates as a unit.
   Its pivot is at the center so rotation is about a vertical axis through the middle. */
.structure {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}

/* Each page face */
.page-face {
  background: #fff;
  border: 2px solid #ccc;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1);
  backface-visibility: hidden;
}

/* Spreadsheet styling */
.spreadsheet {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  font-size: 14px;
}
.spreadsheet th,
.spreadsheet td {
  border: 1px solid #bbb;
  padding: 4px;
  text-align: center;
}
.spreadsheet th {
  background: #f4f4f4;
}

/* Center "holy" area with a pulsing glowing light */
.center-light {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: radial-gradient(circle, rgba(255,255,224,0.9) 0%, rgba(255,255,224,0.1) 70%);
  box-shadow: 0 0 20px 10px rgba(255,255,224,0.8);
  z-index: 10;
}

.light-glow {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { box-shadow: 0 0 10px 5px rgba(255,255,224,0.6); }
  50% { box-shadow: 0 0 30px 15px rgba(255,255,224,0.9); }
  100% { box-shadow: 0 0 10px 5px rgba(255,255,224,0.6); }
}