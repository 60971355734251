@tailwind base;
@tailwind components; 
@tailwind utilities; 

html, body, #root {
  height: 100%;
  margin: 0;
}

mark {
  background-color: #45454a !important;
  color: #ffffff !important;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
}

.reference-highlight {
  background-color: #b0b0b5 !important;
  color: #000 !important;
}

.bg-marble-white {
  background: url('./assets/stone3.png') center center / cover;
  background-color: #ffffff;
  position: relative;
}

.bg-marble-white::before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.8); /* Adjust opacity here */
  z-index: 1;
  pointer-events: none;
}


/* This step is crucial: set an alpha channel directly in the image file itself if above approach isn't sufficient. */

.cool-glow {
  background: linear-gradient(180deg, #45454a 0%, #2c2c2e 100%);
  border-radius: 0.25rem;
  color: #fff !important;
  padding: 0.5rem 1rem;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 2px 3px rgba(0, 0, 0, 0.2);
  transition: background 0.2s, box-shadow 0.2s;
}

.cool-glow:hover {
  background: linear-gradient(180deg, #5a5a5f 0%, #2c2c2e 100%);
}

.cool-glow:focus {
  outline: none;
  box-shadow: 0 0 0 2px #16a34a, 0 0 10px rgba(22, 163, 74, 0.5);
}


/* Updated gray-glow: No box-shadow on the button element */
.gray-glow {
  color: #fff;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  box-shadow: none;
  transition: background 0.2s;
}

.gray-glow:hover,
.gray-glow:focus {
  box-shadow: none;
  outline: none;
}

/* New rules to glow just the text */
.button-text {
  transition: text-shadow 0.2s;
}

.gray-glow:hover .button-text,
.gray-glow:focus .button-text {
  text-shadow: 0 0 10px rgba(200, 200, 200, 0.7);
}

/* Keep a gentle transition for the .logo if desired */
.logo {
  transition: filter 0.2s ease-in-out;
}

/* 
  -----------
  NEW: Circle highlight behind the logo
  -----------
*/
.logo-container {
  position: relative;
  display: inline-block;
}

/* On hover, we create a radial gradient behind the logo */
.logo-container:hover::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  /* Make the circle smaller (80x80) */
  width: 110px;
  height: 110px;
  transform: translate(-50%, -50%);
  border-radius: 50%;

  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.3) 0%,
    transparent 50%
  );

  z-index: 0;
  pointer-events: none;
}


:root {
  --cell-size: 20px;
  --line-width: 2px;
}

.bg-neenah-grid {
  position: relative;
  
  /* Place both the image and grid lines directly in background-image */
  background-image:
    repeating-linear-gradient(
      to right,
      rgba(255, 255, 255, 0.7) 0,
      rgba(255, 255, 255, 0.7) var(--line-width),
      transparent var(--line-width),
      transparent var(--cell-size)
    ),
    repeating-linear-gradient(
      to bottom,
      rgba(255,255,255, 0.7) 0,
      rgba(255,255,255, 0.7) var(--line-width),
      transparent var(--line-width),
      transparent var(--cell-size)
    ),
    url('./assets/neenah-cotton-pearl-white.png');

  background-size:
    var(--cell-size) var(--cell-size),
    var(--cell-size) var(--cell-size),
    var(--cell-size) var(--cell-size);

  background-repeat: repeat, repeat, repeat;
  background-position: top left, top left, top left;
}

/* Semi-transparent white overlay via pseudo-element to control image transparency */
.bg-neenah-grid::before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: rgba(255,255,255,0.6); /* Increase alpha for more transparent image */
  pointer-events: none;
  z-index: 0; /* Place overlay just above background-image (grid & image) */
}

/* All child content clearly above everything else */
.bg-neenah-grid > * {
  position: relative;
  z-index: 1;
}



.header-metal-navy {
  /* Navy metallic gradient */
  background: linear-gradient(135deg, #1d1d22, #5a5a5f, #1d1d22);
  background-size: 200% 200%;
  animation: metallicShine 60s ease-in-out infinite;
  border-bottom: 0px solid #1d1d22;
}

@keyframes metallicShine {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}



.bg-paper {
  position: relative;
  background-color: #fff;
  /* Optionally ensure a stacking context */
  z-index: 0;
}

.bg-paper::before {
  content: "";
  position: absolute;
  inset: 0;
  background-image: url('./assets/neenah-cotton-pearl-white.png');
  background-repeat: repeat;
  background-size: 10px 10px;
  opacity: 0.10;
  filter: none;
  mix-blend-mode: normal; 
  pointer-events: none;

  /* To make sure it's behind the .bg-paper content but not hidden by the parent */
  z-index: -1;
}

/* Ensure content is on top */
.bg-paper > * {
  position: relative;
  z-index: 0;
}