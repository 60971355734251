/* src/pages/demo/MorphingSpreadsheetHighScale.css */
.highscale-scene {
  position: relative;
  width: 1600px;
  height: 1200px;
  margin: 20px auto;
  perspective: 2000px;
  background: radial-gradient(circle, #1e1e1e, #000);
  overflow: hidden;
}

.highscale-structure {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 1s ease;
  /* Auto-rotate for a cool, continuous effect */
  animation: autoRotate 30s linear infinite;
}

@keyframes autoRotate {
  from { transform: rotateY(0deg); }
  to { transform: rotateY(360deg); }
}

.highscale-cell {
  position: absolute;
  background: rgba(0, 150, 255, 0.7);
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 5px rgba(0, 150, 255, 0.8);
  transition: transform 1s ease, background 1s ease;
  will-change: transform;
}

.controls {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
}

.controls button {
  padding: 5px 10px;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

.controls button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.1);
}

.controls label {
  display: flex;
  align-items: center;
  gap: 5px;
}